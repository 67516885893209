$(function(){
	$('.form.autofocus .form-control').keydown(function(e){
		var code = e.keycode || e.which;

		if(code == 13) {

			var form = $(this).closest('.form');
			var nextItem = $(this).parent().next('.form-group').find('.form-control');

			if(nextItem.length > 0) {
				nextItem.focus();
			} else {
				form.submit();
			}
			
			e.preventDefault();
			return false;
		}
	});
	$('.form .form-control').first().focus();

	$('.default-ajax-form').on('submit', function(e){
		var ajaxUrl = $(this).data('ajax-url');
		var successRedirectUrl = $(this).data('success-redirect');
		var form = $(this);
		var data = $(form).serializeArray();

		//fix for none selected checkboxes
		$('input[type="checkbox"]').each(function(){
			var name = $(this).attr('name');
			if($(this).prop('checked') === false) {
				data.push({
					name: name,
					value: 'off'
				});
			}
		});

		$('body').addClass('loading');

		$.ajax({
			type: 'POST',
			data: data,
			url: ajaxUrl
		})
		.done(function(data) {
			if(successRedirectUrl) {
				document.location = successRedirectUrl;
			} else {
				$(form).removeClass('status-error').addClass('status-success');
			}
		})
		.fail(function(xhr) {
			if($(form).find('.alert-error').length == 0) {
				$(form).prepend($('<div>').addClass('alert alert-error').text("Es ist ein Fehler aufgetreten!"));
			}

			var data = JSON.parse(xhr.responseText);
			if(data && data.error) {
				$(form).find('.alert-error').text(data.error);
			}
			
			$(form).removeClass('status-success').addClass('status-error');
		})
		.always(function() {
			$('body').removeClass('loading');
		});

		e.preventDefault();
		return false;
	});
});